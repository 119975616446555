import React from 'react';

import TextPageLayout from '../../layouts/TextPageLayout';

import SplashScreen from './SplashScreen';
import Onboarding from './Onboarding';

const OnboardingPage: React.FC = () => {
    const [renderSplashScreen, setRenderSplashScreen] = React.useState<boolean>(true);

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            setRenderSplashScreen(false);
        }, 2500);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <TextPageLayout>
            {renderSplashScreen ? <SplashScreen /> : <Onboarding />}
        </TextPageLayout>
    );
};

export default OnboardingPage;