import React from 'react';

import Button from '../../components/Button';
import StepBarCounter from '../../components/StepBarCounter';

import { TOnboardingStep } from './interfaces';

import Styles from './styles.module.scss';

const OnboardingStep: React.FC<TOnboardingStep> = ({
    img,
    title,
    description,
    step,
    nbSteps,
    nextButtonCta,
    nextStep
}) => (
    <div className={Styles.body}>
        <p className={Styles.body__logo}>
            <img src={img} alt={`OnboardingStep${step}`} />
        </p>
        <div className={Styles.body__title}>
            <h1>{title}</h1>
            <h2>{description}</h2>
        </div>
        <div className={Styles.body__button}>
            <StepBarCounter className={Styles['body__button--counter']} nbSteps={nbSteps} currentStep={step} />
            <Button styleType="plain" size="large" type="button" label={nextButtonCta} onClick={nextStep} />
        </div>
    </div>
);

export default OnboardingStep;