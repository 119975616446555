import {TProductsResponse, TRequestProductsCallback} from "./interfaces/products";

import * as Cache from '../../../helpers/cache';

const g_getProductsURL = '/products';

export const getProducts: TRequestProductsCallback = (invalidate: boolean) => (
    Cache.api<TProductsResponse>(g_getProductsURL, {}, {
        onValidate: () => !invalidate
    })
);