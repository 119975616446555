import React from "react";
import {AutoComplete, Form} from "antd";
import FloatLabel from "../FloatLabel";
import {IInputAutoComplete} from "./interfaces";

const InputAutoComplete: React.FC<IInputAutoComplete> = ({
    label,
    value,
    setValue,
    fieldName,
    rules,
    className,
    onSearch,
    results
}) => {
    const _label = React.useMemo(() => {
        const rule = rules?.find((_rule: any) => _rule.required === true);

        return rule ? `${label}*` : label;
    }, [label, rules]);

    return (
        <FloatLabel label={_label} value={value} className={className}>
            <Form.Item
                name={fieldName}
                initialValue={value}
                rules={rules}
            >
                <AutoComplete
                    onSearch={onSearch}
                    onSelect={(newValue: string) => setValue(newValue)}
                    onChange={(newValue) => setValue(newValue)}
                    style={{fontSize: 16}}
                >
                    {results.map((result: string) => (
                        <AutoComplete.Option key={result} value={result}>
                            {result}
                        </AutoComplete.Option>
                    ))}
                </AutoComplete>
            </Form.Item>
        </FloatLabel>
    )
}

export default InputAutoComplete