// import React from "react";

// import * as ScanditSDK from "scandit-sdk";

// import Styles from './styles.module.scss';
// import { IScannerProps } from "./interfaces";

// const Scanner = React.forwardRef<any, IScannerProps>(({
//     onResult,
// }, ref) => {
//     const videoRef = React.createRef<any>();
//     const barcodePickerRef = React.useRef<ScanditSDK.BarcodePicker>();

//     React.useImperativeHandle(ref, () => ({
//         stop: () => {
//             barcodePickerRef.current?.destroy();
//         }
//     }));

//     React.useEffect(() => {
//         // if (navigator?.mediaDevices) {
//         //     navigator.mediaDevices.addEventListener = navigator.mediaDevices.addEventListener || ((eventName: string, callback: CallableFunction) => {
//         //         if (navigator.mediaDevices.hasOwnProperty(`on${eventName}`)) {
//         //             (navigator.mediaDevices as any)[`on${eventName}`] = callback;
//         //         }
//         //     });
//         // }

//         ScanditSDK.configure(process.env.REACT_APP_SCANDIT_SDK!, {
//             engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk/build",
//         })
//         .then(() => ScanditSDK.BarcodePicker.create(videoRef.current, {
//             playSoundOnScan: false,
//             vibrateOnScan: false,
//             enableTorchToggle: false,
//             enableCameraSwitcher: false,
//             viewfinderArea: {
//                 x: 0.15,
//                 y: 0.30,
//                 width: 0.7,
//                 height: 0.4
//             },
//             guiStyle: ScanditSDK.BarcodePicker.GuiStyle.VIEWFINDER,
//             cameraType: ScanditSDK.Camera.Type.BACK,
//             videoFit: ScanditSDK.BarcodePicker.ObjectFit.COVER,
//         }))
//         .then(barcodePicker => {
//             const scanSettings = new ScanditSDK.ScanSettings({
//                 enabledSymbologies: [
//                     ScanditSDK.Barcode.Symbology.CODE128,
//                     ScanditSDK.Barcode.Symbology.CODE39,
//                     ScanditSDK.Barcode.Symbology.QR
//                 ],
//                 codeDuplicateFilter: 1000, // Minimum delay between duplicate results
//             });
//             barcodePicker.applyScanSettings(scanSettings);
//             barcodePicker.on('scan', scanResult => {
//                 onResult(scanResult.barcodes[0].data);
//             });
//             barcodePickerRef.current = barcodePicker;
//         })
//         .catch(console.error);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);

//     return (
//         <div className={Styles["scanner"]}>
//             <div ref={videoRef} className={`viewport ${Styles["scanner__viewport"]}`} />
//             <div className={Styles["scanner__blur"]}>
//                 <div className={Styles["scanner__areaBar"]} />
//             </div>
//         </div>
//     )
// });

// export default Scanner;

import { useRef, forwardRef, useImperativeHandle } from 'react';
import * as SDCCore from 'scandit-web-datacapture-core';
import * as SDCBarcode from 'scandit-web-datacapture-barcode';
import { IScannerProps } from "./interfaces";

const licenseKey = process.env.REACT_APP_SCANDIT_SDK!;

  const Scanner = forwardRef<any, IScannerProps>(({
        onResult,
    }, ref) => {

    const barcodePickerRef = useRef<any>();

    useImperativeHandle(ref, () => ({
        stop: () => {
            barcodePickerRef.current?.destroy();
        }
    }));
    async function runScanner() {

      await SDCCore.configure({
        licenseKey:licenseKey,
        libraryLocation:'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/',
        moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
      });

      const context = await SDCCore.DataCaptureContext.create();

      const camera = SDCCore.Camera.default;
      await context.setFrameSource(camera);

      const settings = new SDCBarcode.BarcodeCaptureSettings();
      settings.enableSymbologies([
        SDCBarcode.Symbology.Code128,
        SDCBarcode.Symbology.Code39,
        SDCBarcode.Symbology.QR,
      ]);

      const symbologySetting = settings.settingsForSymbology(SDCBarcode.Symbology.Code39);
      symbologySetting.activeSymbolCounts = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

      const barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(context, settings);
      await barcodeCapture.setEnabled(false);

      barcodeCapture.addListener({
        didScan:async (barcodeCapture, session) => {

          await barcodeCapture.setEnabled(false);
          const barcode = session.newlyRecognizedBarcodes[0];
          const symbology = new SDCBarcode.SymbologyDescription(barcode.symbology);

          showResult(barcode.data, symbology.readableName);
          await barcodeCapture.setEnabled(true);

       },
     });

     const view = await SDCCore.DataCaptureView.forContext(context);

     const targetElement = document.getElementById("data-capture-view");

    if (targetElement !== null) {
    view.connectToElement(targetElement);
    } else {
    console.error("Element with id 'data-capture-view' not found");
    }

    // view.connectToElement(document.getElementById("data-capture-view"));
     view.addControl(new SDCCore.CameraSwitchControl());

     const barcodeCaptureOverlay = 
       await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
         barcodeCapture,
         view,
         SDCBarcode.BarcodeCaptureOverlayStyle.Frame
       );

     const viewfinder = new SDCCore.RectangularViewfinder(
       SDCCore.RectangularViewfinderStyle.Rounded,
       SDCCore.RectangularViewfinderLineStyle.Bold,
     );

     await barcodeCaptureOverlay.setViewfinder(viewfinder); 

     await camera.switchToDesiredState(SDCCore.FrameSourceState.On);
     await barcodeCapture.setEnabled(true);
  }

  function showResult(data: string | null, symbology: string) {
    onResult(data!);
  }

  runScanner().catch((error) => {
    console.error(error);
    alert(error);
  });

  return(
    <div id="data-capture-view"></div>
  )
});

export default Scanner;