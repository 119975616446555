enum ERoutes {
    
    /**
     * Default home page route.
     */
    Home = '/',

    /**
     * Onboarding page
     */
    Onboarding = '/Onboarding',

    /**
     * scanner route
     */
    Scanner = '/scanner',

    /**
     * product route
     */
    Product = '/product',

    /**
     * cart route
     */
    Cart = '/cart',

    /**
     * order route
     */
    Order = '/order',

    /**
     * end screen route
     */
    OrderSuccess = '/order/success',

    /**
     * error after order
     */
    OrderError = '/order/error',

    /**
     * partial error after order
     */
    PartialOrderError = '/order/error/partial',

    /**
     * delivery screen
     */
    Delivery = '/delivery',

    /**
     * delete cart confirmation screen
     */
    DeleteCart = '/cart/delete'
}

export default ERoutes;