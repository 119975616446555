import React, {useState} from "react";
import {Form, Input} from "antd";
import FloatLabel from "../FloatLabel";
import {IInputSearch} from "./interfaces";

const InputSearch: React.FC<IInputSearch> = ({
    label,
    fieldName,
    rules,
    onClick,
    onSearch,
}) => {
    const [value, setValue] = useState("")

    return (
        <FloatLabel label={label} value={value}>
            <Form.Item
                name={fieldName}
                initialValue={value}
                rules={rules}
            >
                <Input.Search
                    onChange={e => setValue(e.target.value)}
                    onClick={onClick}
                    onSearch={onSearch}
                />
            </Form.Item>
        </FloatLabel>
    )
}

export default InputSearch
