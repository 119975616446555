import API from "../index";
import {ESearchType, TClientsResponse, TRequestClientsCallback} from "./interfaces/clients";

export const getClients: TRequestClientsCallback = ({
    repCode,
    accountCode,
    accountName,
    email,
    authToken,
    searchType = ESearchType.client,
    subsidiaryCode
}) => {
    return API.get<TClientsResponse>('/clients', {
        params: {
            'reps_code': repCode,
            'account_code': accountCode,
            'account_name': accountName,
            'email': email,
            'search_type': searchType,
            'subsidiary_code': subsidiaryCode
        },
        headers: {
            'Authorization': `Bearer ${authToken}`
        }

    })
        .then(({ data }) => data)
}