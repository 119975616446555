import React from "react";

import { Form } from "antd";

import { LocaleConsumer } from '../../providers/LocaleProvider';

import Button from "../../components/Button";
import InputText from "../../components/InputText";
import InputPassword from "../../components/InputPassword";

import Styles from "./styles.module.scss";
import { ILoginForm } from "./interfaces";

const LoginForm: React.FC<ILoginForm> = ({onSubmit}) => {
    const [email, setEmail] = React.useState("")

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <Form onFinish={onSubmit} className={Styles["login__form"]} action="">
                    <InputText
                        label={getTranslation("login_page.form.email")}
                        fieldName="email"
                        value={email}
                        setValue={setEmail}
                        rules={[
                            {
                                type: "email",
                                message: getTranslation("order_page.form.invalid_email"),
                                validateTrigger: 'onBlur'
                            },
                            {
                                required: true,
                                message: getTranslation("login_page.form.email_empty_error"),
                            },
                        ]}
                        className={Styles["login__email"]}
                        inputType="email"
                    />
                    <InputPassword
                        label={getTranslation("login_page.form.password")}
                        fieldName="password"
                        rules={[
                            {
                                required: true,
                                message: getTranslation("login_page.form.password_empty_error"),
                            },
                        ]}
                        className={Styles["login__password"]}
                    />
                    <p className={Styles["login__mandatory"]}>{getTranslation("login_page.form.mandatory")}</p>
                    <Button
                        type="submit"
                        styleType="plain"
                        size="large"
                        label={getTranslation("login_page.form.submit")}
                        className={Styles["login__submit"]}
                    />
                </Form>
            )}
        </LocaleConsumer>
    )
}

export default LoginForm