import React, {useContext} from "react";
import Styles from './styles.module.scss'
import {IMenu} from "./interfaces";
import logo from "../../globals/assets/logo_PF.png"
import ERoutes from "../../routers/AuthRouter/routes";
import * as NotAuthRoutes from "../../routers/NotAuthRouter/routes";
import EGlobalRoutes from "../../routers/AppRouter/routes"
import {Link} from "react-router-dom";
import {AuthContext} from "../../providers/AuthProvider";
import {LocaleConsumer, LocaleContext} from '../../providers/LocaleProvider';
import {ELocale} from "../../providers/LocaleProvider/interfaces";
import Button from "../Button";
import SwitchSelect from "../SwitchSelect";
import {CartContext} from "../../providers/CartProvider";
import { CloseOutlined } from "@ant-design/icons";

const Menu: React.FC<IMenu> = ({isOpen, setIsOpen}) => {
    const authContext = useContext(AuthContext)
    const localeContext = useContext(LocaleContext)
    const cartContext = useContext(CartContext)

    const disconnect = () => {
        cartContext.empty()
        authContext.logout()
        setIsOpen(false);
    }

    const closeMenu = React.useCallback(() => setIsOpen(false), [setIsOpen]);

    const onToggleLocale = (checked: boolean) => {
        localeContext.setLocale(checked ? ELocale.frFr : ELocale.enGB);
    }

    return (
        <LocaleConsumer>
            {({ locale, getTranslation }) => (
                <>
                    <div className={Styles["menu"]}>
                        <div onClick={closeMenu} className={Styles["menu__header__close"]}>
                            <CloseOutlined className={Styles["menu__header__closeIcon"]} />
                        </div>
                        <div className={Styles["menu__main"]}>
                            <img alt="logo" src={logo} className={Styles["menu__logo"]}/>
                            <Link to={ERoutes.Scanner} onClick={() => setIsOpen(false)} className={Styles["menu__link"]}>
                                <p className="title">{getTranslation("menu.scan")}</p>
                            </Link>
                            <Link to={ERoutes.Cart} onClick={() => setIsOpen(false)} className={Styles["menu__link"]}>
                                <p className="title">{getTranslation("menu.cart")}</p>
                            </Link>
                            <Link onClick={closeMenu} to={ERoutes.DeleteCart} className={Styles["menu__link"]}>
                                <p className="title">{getTranslation("menu.new_order")}</p>
                            </Link>
                            <Link onClick={disconnect} to={NotAuthRoutes.default.Home} className={Styles["menu__link"]}>
                                <p className="title">{getTranslation("menu.disconnect")}</p>
                            </Link>
                        </div>
                        <div className={Styles["menu__footer"]}>
                            <SwitchSelect leftLabel={getTranslation("menu.english")} rightLabel={getTranslation("menu.french")} onSwitch={onToggleLocale} defaultValue={locale === ELocale.frFr} />
                            <Button onClick={closeMenu} type="button" link={EGlobalRoutes.PrivacyPolicy} styleType="text" size="large" label={getTranslation("menu.privacy_policy")} />
                        </div>
                    </div>
                </>
            )}
        </LocaleConsumer>
    )
}

export default Menu