import React from "react";
import {ECartActionType, ICartProps, TCartItem} from "./interfaces";
import reducer from "./reducer";

export const CartContext = React.createContext<ICartProps>({
    items: [],
    add: () => {},
    remove: () => {}, // remove one element
    eradicate: () => {}, // clear all specified element
    empty: () => {}, // empty cart
});

CartContext.displayName = 'Cart';

export const CartConsumer = CartContext.Consumer;

const CartProvider: React.FC = ({
    children
}) => {
    const [state, dispatch] = React.useReducer(reducer, JSON.parse(localStorage.getItem("cart") as string) || {items: []});

    const cartContext = React.useMemo<ICartProps>(() => ({
        ...state,

        add: item => {
            const cartItem: TCartItem = {product: item, number: 1}
            let prevItem = state.items.find(elem => elem.product.code === item.code && elem.product.size === item.size)
            // No more than 3 items per product
            if (prevItem && prevItem.number >= 3) {
                return
            }
            if (prevItem) {
                prevItem.number += 1
                dispatch({type: ECartActionType.Edit, item: prevItem})
            } else {
                dispatch({type: ECartActionType.Set, item: cartItem})
            }
        },

        remove: item => {
            let prevItem = state.items.find(elem => elem.product.code === item.code && elem.product.size === item.size)

            if (state.items && prevItem) {
                prevItem.number -= 1
                if (prevItem.number === 0) {
                    dispatch({type: ECartActionType.Delete, item: {product: item, number: 0}})
                } else {
                    dispatch({type: ECartActionType.Edit, item: prevItem})
                }
            }
        },

        eradicate: item => {
            dispatch({type: ECartActionType.Delete, item: {product: item, number: 0}})
        },

        empty: () => {
            dispatch({type: ECartActionType.Empty})
        },
    }), [state])

    return (
        <CartContext.Provider value={cartContext}>
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider