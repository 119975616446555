import { TInjectLocaleIntoAPIOptions } from "../../helpers/API/interfaces";

export enum ELocale {
    /**
     * English Great Britain locale
     */
    enGB = 'en-GB',

    /**
     * French metropolitan locale
     */
    frFr = 'fr-FR'
}

export type TTranslationValue = [string] | [string, string];

export type TTranslation = {
    /**
     * Key is the translation's key use by LocaleProvider to associate translations values.
     * The first value is singular version, the second is plural version.
     */
    [key: string]: TTranslationValue | TTranslation;
}

export type TLocaleTranslation = {
    /**
     * Global translation object, the key here is the current locale.
     */
    [key in ELocale]?: TTranslation;
}

type TTranslationParam = string | number;

type TTranslationOptionParam = {
    [key: string]: TTranslationParam;
};

export type TTGetTranslationOptions = {
    /**
     * Parameters inserted into formatted text.
     */
    params?: TTranslationOptionParam | any[];

    /**
     * Get Plural version (default: false)
     */
    plural?: boolean;
};

export type TGetTranslationCallback = (key: string, options?: TTGetTranslationOptions) => string;

export type TSetLocaleCallback = (value: ELocale) => void;

export type _TLocaleState = {
    /**
     * Next Locale value, used only when using remote server otherwise, it's always same value as locale.
     * It's allowing to inform request that need languages as parameters to submit new request at same time.
     * In addition, it's allowing to wait for translations value before change languages.
     */
    nextLocale: ELocale;

    /**
     * Current Locale value
     */
    locale: ELocale;
}

export type TLocaleState = _TLocaleState & {
    /**
     * Page is loading
     */
    loading: boolean;
}

export enum ELocaleActionType {
    /**
     * Set Locale on provider for remote version
     */
    SetRemote = 'LOCALE_SET_REMOTE',

    /**
     * Set Locale on provider for local version.
     */
    SetLocal = 'LOCALE_SET_LOCAL',

    /**
     * Update current Locale using transition locale's value on remove version.
     */
    UpdateRemote = 'LOCALE_UPDATE_REMOTE',
}

export type TLocaleAction = {
    /**
     * type of reducer's action
     */
    type: ELocaleActionType;

    /**
     * reducer's action parameter need to set locale
     */
    locale?: TLocaleState['locale'];
}

export type TLocaleReducer = (prevState: TLocaleState, action: TLocaleAction) => TLocaleState;

export type TLocaleContext = _TLocaleState & {
    /**
     * Method use to change locale.
     */
    setLocale: TSetLocaleCallback;

    /**
     * Method use to get translation's text.
     */
    getTranslation: TGetTranslationCallback;
}

export type TLocaleProps = {
    /**
     * Initial translations value.
     * Useful if you want to use locale value, it'll be loaded during onmount event.
     */
    translations?: TLocaleTranslation;

    /**
     * Method use to retrives Translations from remote server.
     */
    getTranslationFromRemote?: (locale: ELocale) => Promise<TTranslation | undefined>;

    /**
     * Config getTranslation methods to print key if translation is not found and keep it empty.
     * default: print key only on developement mode.
     */
    renderKeyIfNotFound?: boolean;

    /**
     * Component render during loading.
     */
    renderLoading?: React.ReactElement<any, any> | null;

    /**
     * Options use to defined how inject locale into requests.
     */
    injectLocaleIntoAPIOptions?: TInjectLocaleIntoAPIOptions;
};