import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { AuthContext } from '../../providers/AuthProvider';
import { LocaleContext } from '../../providers/LocaleProvider';
import { OnboardingContext } from '../../providers/OnboardingProvider';

import MultiStep, { TMultiStepRef } from '../../components/MultiStep';

import { updateOnboardingState } from '../../helpers/API/requests/onboarding';

import OnboardingStep from './OnboardingStep';

import OnboardingStep1Image from '../../globals/assets/onboarding_step1.svg';
import OnboardingStep2Image from '../../globals/assets/onboarding_step2.svg';
import OnboardingStep3Image from '../../globals/assets/onboarding_step3.svg';
// import OnboardingStep4Image from '../../globals/assets/onboarding_step4.svg';

import Styles from './styles.module.scss';

const nbSteps = 3;

const Onboarding: React.FC = () => {
    const { user } = React.useContext(AuthContext);
    const { getTranslation } = React.useContext(LocaleContext);
    const { canSkipNextTime } = React.useContext(OnboardingContext);

    const history = useHistory();
    const { state: nextRoute } = useLocation<any>();

    const multiStep = React.useRef<TMultiStepRef>(null);
    const [hasPrevStep, setHasPrevStep] = React.useState<boolean>(false);

    const onStepChange = React.useCallback((newStep:TMultiStepRef['currentStep']) => {
        setHasPrevStep(newStep.index !== 1);
    }, []);

    const skipNextTime = React.useCallback(() => {
        canSkipNextTime();
        return updateOnboardingState(user!.username!);
    }, [canSkipNextTime, user]);

    const onSubmitLastStep = React.useCallback(() => {
        skipNextTime()
        .finally(() => {
            history.push(nextRoute);
        });
    }, [history, nextRoute, skipNextTime]);

    const prevStep = React.useCallback(() => {
        multiStep.current!.prev();
    }, []);
    
    const nextStep = React.useCallback(() => {
        multiStep.current!.next();
    }, []);

    return (
        <section className={Styles.container}>
            <header className={Styles.header}>
                <p onClick={hasPrevStep ? prevStep : undefined}>{hasPrevStep && getTranslation('onboarding.cta_previous')}</p>
                <p><Link onClick={skipNextTime} to={nextRoute} >{getTranslation('onboarding.cta_close')}</Link></p>
            </header>
            <MultiStep
                ref={multiStep}
                onStepChange={onStepChange}
                onSubmitLastStep={onSubmitLastStep}
            >
                <MultiStep.Step name='scan'>
                    <OnboardingStep
                        img={OnboardingStep1Image}
                        title={getTranslation('onboarding.screen1.title')}
                        description={getTranslation('onboarding.screen1.description')}
                        step={1}
                        nbSteps={nbSteps}
                        nextButtonCta={getTranslation('onboarding.cta_continue')}
                        nextStep={nextStep}
                    />
                </MultiStep.Step>
                <MultiStep.Step name='order'>
                    <OnboardingStep
                        img={OnboardingStep2Image}
                        title={getTranslation('onboarding.screen2.title')}
                        description={getTranslation('onboarding.screen2.description')}
                        step={2}
                        nbSteps={nbSteps}
                        nextButtonCta={getTranslation('onboarding.cta_continue')}
                        nextStep={nextStep}
                    />
                </MultiStep.Step>
                <MultiStep.Step name='take'>
                    <OnboardingStep
                        img={OnboardingStep3Image}
                        title={getTranslation('onboarding.screen3.title')}
                        description={getTranslation('onboarding.screen3.description')}
                        step={3}
                        nbSteps={nbSteps}
                        // nextButtonCta={getTranslation('onboarding.cta_continue')}
                        nextButtonCta={getTranslation('onboarding.cta_start')}
                        nextStep={nextStep}
                    />
                </MultiStep.Step>
                {/* <MultiStep.Step name='disconnect'>
                    <OnboardingStep
                        img={OnboardingStep4Image}
                        title={getTranslation('onboarding.screen4.title')}
                        description={getTranslation('onboarding.screen4.description')}
                        step={4}
                        nbSteps={nbSteps}
                        nextButtonCta={getTranslation('onboarding.cta_start')}
                        nextStep={nextStep}
                    />
                </MultiStep.Step> */}
            </MultiStep>
        </section>
    );
};

export default Onboarding;