enum EGlobalRoutes {
    
    /**
     * Privacy policy page
     */
    PrivacyPolicy = '/privacy_policy',

    /**
     * Information page
     */
    Infos = '/info'
}

export default EGlobalRoutes;