import React from "react";
import Styles from "./styles.module.scss";
import {CartConsumer} from "../../providers/CartProvider";
import ItemRow from "../ItemRow";
import {LocaleConsumer} from "../../providers/LocaleProvider";

const ItemList: React.FC = () => {
    return (
      <LocaleConsumer>
        {({ getTranslation }) => (
          <div className={Styles["itemList"]}>
              <CartConsumer>
                  {({ items, remove, add, eradicate }) =>
                      items.length > 0 ? items.map((item) => (
                              <ItemRow key={item.product.code + item.product.size} item={item} add={add} remove={remove} eradicate={eradicate} />
                          )
                      ) : <h2 className={Styles["itemList__emptyText"]}>{getTranslation("cart_page.empty")}</h2>
                  }
              </CartConsumer>
          </div>
        )}
      </LocaleConsumer>
    )
}

export default ItemList
