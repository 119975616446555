import React from "react";
import {IItemRow} from "./interfaces";
import Styles from "./styles.module.scss";
import decrementImg from "../../globals/assets/minus.svg"
import incrementImg from "../../globals/assets/plus.svg"
import trashImg from "../../globals/assets/trash.svg"
import ERoutes from "../../routers/AuthRouter/routes";
import {useHistory} from "react-router-dom";
import {LocaleConsumer} from '../../providers/LocaleProvider';

const ItemRow: React.FC<IItemRow> = ({
    item,
    add,
    remove,
    eradicate
}) => {
    let history = useHistory()
    const productPage = () => {
        history.push(`${ERoutes.Product}?code=${item.product.code}`)
    }

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <div key={item.product.code} className={Styles["itemRow"]} >
                    <img alt={item.product.designation} src={item.product.pictureUrl} className={Styles["itemRow__image"]} onClick={productPage}/>
                    <div className={Styles["itemRow__info"]}>
                        <div onClick={productPage}>
                            <p className={Styles["itemRow__name"]}>{item.product.pattern} {item.product.color}</p>
                            <p className={Styles["itemRow__code"]}>{item.product.code + ' - ' + getTranslation("cart_page." + item.product.size)}</p>
                        </div>
                        <div className={Styles["itemRow__increment"]}>
                            <button className={Styles["itemRow__incrementButton"]} onClick={() => remove(item.product)}>
                                <img alt="decrement" src={decrementImg} />
                            </button>
                            <p className={Styles["itemRow__number"]}>{item.number}</p>
                            {item.number < 3 &&
                                <button className={Styles["itemRow__incrementButton"]} onClick={() => add(item.product)}>
                                    <img alt="increment" src={incrementImg} />
                                </button>
                            }
                            <button className={Styles["itemRow__delete"]} onClick={() => eradicate(item.product)}>
                                <img alt="delete" src={trashImg} />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </LocaleConsumer>
    )
}

export default ItemRow