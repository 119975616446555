import API from "../index";

import { TRequestOnboardingCallback } from "./interfaces/onboarding";

export const updateOnboardingState = (email: string) => 
    API.put<TRequestOnboardingCallback>(
        '/onboarding',
        {
            email
        }
    )
    .then(({ data }) => data)
;
