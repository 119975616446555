import React, {useContext, useEffect, useState} from "react";
import Styles from "./styles.module.scss"
import {useHistory, useLocation} from "react-router-dom";
import ERoutes from "../../../routers/AuthRouter/routes";
import BackgroundImageLayout from "../../../layouts/BackgroundImageLayout";
import Button from "../../../components/Button";
import backgroundImage from "../../../globals/assets/order_success.png"
import image from "../../../globals/assets/warning.svg"
import * as NotAuthRoutes from "../../../routers/NotAuthRouter/routes";
import {AuthContext} from "../../../providers/AuthProvider";
import {CartContext} from "../../../providers/CartProvider";
import {LocaleConsumer} from "../../../providers/LocaleProvider";
import HTMLRender from "../../../components/HTMLRender";
import API from "../../../helpers/API";

const PartialOrderError: React.FC = () => {
    const authContext = useContext(AuthContext)
    const cartContext = useContext(CartContext)
    const history = useHistory();
    const location = useLocation();
    const [code, setCode] = useState<number>(0)
    const [successNb, setSuccessNb] = useState<number>(0)
    const [errorNb, setErrorNb] = useState<number>(0)

    useEffect(() => {
        // @ts-ignore
        setCode(location.state.code)
        // @ts-ignore
        setSuccessNb(location.state.successNb)
        // @ts-ignore
        setErrorNb(location.state.errorNb)
    }, [location])

    const newOrder = () => {
        cartContext.empty()
        if (!authContext.user?.isRep) {
            history.push(ERoutes.Scanner)
        } else {
            const token = API.getTokenFromCookie!()
            const showroom = authContext.user.showroom
            authContext.logout()
            history.push({pathname: NotAuthRoutes.default.SelectClient, state: {token: token, showroom: showroom}})
        }
    }

    const disconnect = () => {
        cartContext.empty()
        authContext.logout()
        history.push(NotAuthRoutes.default.Home)
    }

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <BackgroundImageLayout image={backgroundImage}>
                    <div className={Styles["OrderError"]}>
                        <div className={Styles["OrderError__infos"]}>
                            <img alt="logo" src={image} className={Styles["OrderError__img"]}/>
                            <h2>
                                <HTMLRender>
                                    {getTranslation(
                                        "order_error_page.partial_error",
                                { params: {
                                        code: code,
                                        successNb: successNb,
                                        errorNb: errorNb
                                    }})}
                                </HTMLRender>
                            </h2>
                        </div>
                        <div className={Styles["OrderError__buttons"]}>
                            <Button styleType="plain" size="small" type="button"
                                    label={getTranslation("order_error_page.disconnect")} onClick={disconnect}
                                    className={Styles["OrderError__buttonDisconnect"]}/>
                            <Button styleType="outlined" size="small" type="button"
                                    label={getTranslation("order_error_page.new_order")} onClick={newOrder}/>
                        </div>
                    </div>
                </BackgroundImageLayout>
            )}
        </LocaleConsumer>
    )
}

export default PartialOrderError