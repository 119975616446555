import React from "react";
import {IBackgroundImageLayout} from "./interfaces";
import Styles from './styles.module.scss'

const BackgroundImageLayout: React.FC<IBackgroundImageLayout> = ({
    image,
    children
}) => {
    return (
        <div
            className={Styles["background_image_container"]}
            style={{
                backgroundImage:  `url(${image})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className={Styles["background_image_inner"]}>
                {children}
            </div>
        </div>
    )
}

export default BackgroundImageLayout