import React, {useContext} from "react";
import Styles from "./styles.module.scss"
import {useHistory} from "react-router-dom";
import ERoutes from "../../../routers/AuthRouter/routes";
import BackgroundImageLayout from "../../../layouts/BackgroundImageLayout";
import Button from "../../../components/Button";
import backgroundImage from "../../../globals/assets/order_success.png"
import image from "../../../globals/assets/warning.svg"
import * as NotAuthRoutes from "../../../routers/NotAuthRouter/routes";
import {AuthContext} from "../../../providers/AuthProvider";
import {CartContext} from "../../../providers/CartProvider";
import {LocaleConsumer} from "../../../providers/LocaleProvider";

const OrderError: React.FC = () => {
    const authContext = useContext(AuthContext)
    const cartContext = useContext(CartContext)
    const history = useHistory();

    const newOrder = () => {
        history.push(ERoutes.Scanner)
    }

    const disconnect = () => {
        cartContext.empty()
        authContext.logout()
        history.push(NotAuthRoutes.default.Home)
    }

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <BackgroundImageLayout image={backgroundImage}>
                    <div className={Styles["OrderError"]}>
                        <div className={Styles["OrderError__infos"]}>
                            <img alt="logo" src={image} className={Styles["OrderError__img"]}/>
                            <h2>{getTranslation("order_error_page.description")}</h2>
                        </div>
                        <div className={Styles["OrderError__buttons"]}>
                            <Button styleType="plain" size="small" type="button"
                                    label={getTranslation("order_error_page.disconnect")} onClick={disconnect}
                                    className={Styles["OrderError__buttonDisconnect"]}/>
                            <Button styleType="outlined" size="small" type="button"
                                    label={getTranslation("order_error_page.new_order")} onClick={newOrder}/>
                        </div>
                    </div>
                </BackgroundImageLayout>
            )}
        </LocaleConsumer>
    )
}

export default OrderError