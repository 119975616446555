import React, {useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import ERoutes from "../../../routers/NotAuthRouter/routes";
import Styles from './styles.module.scss';
import logo from '../../../globals/assets/logo_PF.png'
import backgroundImage from "../../../globals/assets/metier_papier_peint.png";
import BackgroundImageLayout from "../../../layouts/BackgroundImageLayout";
import { LocaleConsumer } from '../../../providers/LocaleProvider';
import useQuery from "../../../hooks/useQuery";
import {ShowroomsContext} from "../../../providers/ShowroomProvider";
import HTMLRender from "../../../components/HTMLRender";

const Home : React.FC = () => {
    let query = useQuery()
    const showroom = query.get("showroom")
    const showroomsContext = useContext(ShowroomsContext)
    const history = useHistory();

    useEffect(() => {
        if (showroom && showroom !== "null" && showroomsContext.showrooms.find(elem => elem.showroomCode === showroom)) {
            history.push({pathname: ERoutes.Login,  search: `?showroom=${showroom}`})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <BackgroundImageLayout image={backgroundImage}>
                    <div className={Styles["menu"]}>
                        <img className={Styles["logo"]} alt="logo" src={logo} />
                            <>
                                <div className={Styles["welcome"]}><HTMLRender>{getTranslation("home_page.welcome")}</HTMLRender></div>
                                <p className={Styles["welcome"]}>{getTranslation("home_page.scan_qr")}</p>
                            </>
                    </div>
                </BackgroundImageLayout>
            )}
        </LocaleConsumer>
    )
}

export default Home;