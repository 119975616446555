import React from "react";
import Styles from './styles.module.scss'

const BottomMenuLayout: React.FC = ({
    children
}) => {
    return (
        <div className={Styles["bottomMenuLayout"]}>
            {children}
        </div>
    )
}

export default BottomMenuLayout