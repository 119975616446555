import jwt_decode from "jwt-decode";

export interface JwtCustomPayload {
    iat?: string;
    exp?: string;
    username?: string;
    firstname?: string;
    lastname?: string;
    clientAccountNumber?: string;
    isClient?: boolean;
    isRep?: boolean;
    isManager?: boolean;
    commercialRepCode?: string[];
    managerRepCode?: string[];
    skipOnboarding: boolean;
}

export const getExpirationDate = (token: string, tokenExpirationField = 'exp', tokenTimestampShift = 1000): Date | undefined => {
    let expires = jwt_decode<any>(token)[tokenExpirationField] as Date | number | undefined;

    if (typeof expires === 'number') {
        expires = new Date(expires * tokenTimestampShift);
    }

    return expires;
}

export const getTokenData = (token: string): JwtCustomPayload => {
    return jwt_decode<JwtCustomPayload>(token)
}