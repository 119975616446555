import React, {useContext} from "react";
import Styles from "./styles.module.scss"
import {CartContext} from "../../../providers/CartProvider";
import {useHistory} from "react-router-dom";
import ERoutes from "../../../routers/AuthRouter/routes";
import * as NotAuthRoutes from "../../../routers/NotAuthRouter/routes";
import BackgroundImageLayout from "../../../layouts/BackgroundImageLayout";
import Button from "../../../components/Button";
import backgroundImage from "../../../globals/assets/error_page.png"
import image from "../../../globals/assets/warning.svg"
import {LocaleConsumer} from "../../../providers/LocaleProvider";
import {AuthContext} from "../../../providers/AuthProvider";
import API from "../../../helpers/API";

const DeleteCart: React.FC = () => {
    const cartContext = useContext(CartContext)
    const authContext = useContext(AuthContext)
    const history = useHistory();

    const cancel = () => {
        history.goBack()
    }

    const newOrder = () => {
        cartContext.empty()
        if (!authContext.user?.isRep) {
            history.push(ERoutes.Scanner)
        } else {
            const token = API.getTokenFromCookie!()
            const showroom = authContext.user.showroom
            authContext.logout()
            history.push({pathname: NotAuthRoutes.default.SelectClient, state: {token: token, showroom: showroom}})
        }
    }

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <BackgroundImageLayout image={backgroundImage}>
                    <div className={Styles["deleteCart"]}>
                        <div className={Styles["deleteCart__infos"]}>
                            <img alt="logo" src={image} className={Styles["deleteCart__img"]} />
                            <h2>{getTranslation("delete_cart_page.description")}</h2>
                        </div>
                        <div className={Styles["deleteCart__buttons"]}>
                            <Button styleType="plain" size="small" type="button" label={getTranslation("delete_cart_page.yes")} onClick={newOrder} className={Styles["deleteCart__buttonYes"]}/>
                            <Button styleType="outlined" size="small" type="button" label={getTranslation("delete_cart_page.no")} onClick={cancel} />
                        </div>
                    </div>
                </BackgroundImageLayout>
            )}
        </LocaleConsumer>
    )
}

export default DeleteCart