import React from "react";

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import InputText from "../InputText";

import { TInputPassword } from "./interfaces";
import Styles from './styles.module.scss';

const InputPassword: React.FC<TInputPassword> = ({
    label,
    value,
    fieldName,
    error = null,
    dependencies,
    rules,
    className,
}) => {
    const [_value, setValue] = React.useState('');
    const [isVisible, setVisible] = React.useState(false);

    return (
        <div className={Styles.container}>
            <InputText
                label={label}
                value={_value}
                setValue={setValue}
                fieldName={fieldName}
                error={error}
                dependencies={dependencies}
                rules={rules}
                className={`${Styles.input}${className ? ` ${className}` : ''}`}
                inputType={isVisible ? 'text' : 'password'}
            />
            {isVisible ? <EyeOutlined className={Styles.icon} onClick={() => setVisible(false)} /> : <EyeInvisibleOutlined className={Styles.icon} onClick={() => setVisible(true)} />}
        </div>
    )
}

export default InputPassword;