import React, {useState} from "react";
import Styles from './styles.module.scss';
import {ISwitchSelect} from "./interfaces";
import Switch from "react-switch";
import {Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

const SwitchSelect: React.FC<ISwitchSelect> = ({
    leftLabel,
    rightLabel,
    onSwitch,
    defaultValue,
    rightTooltip,
    leftTooltip
}) => {
    const [checked, setChecked] = useState(defaultValue)

    const onChange  = (checked: boolean, event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent, id: string) => {
        setChecked(checked)
        onSwitch(checked, event, id)
    }

    return (
        <div className={Styles["switchSelect"]}>
            {leftTooltip ?
                <Tooltip title={leftTooltip} placement="topLeft" className={Styles["switchSelect__tooltip"]}>
                    <p>{leftLabel}</p>
                    <InfoCircleOutlined />
                </Tooltip>
                :
                <p className={Styles["switchSelect__text"]}>{leftLabel}</p>
            }
            <div className={Styles["switchSelect__switchContainer"]}>
                <div className={Styles["switchSelect__background"]} />
                <Switch
                    checked={checked}
                    onChange={onChange}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onHandleColor={"#2D2D2D"}
                    offHandleColor={"#2D2D2D"}
                    onColor={"#fff"}
                    offColor={"#fff"}
                    handleDiameter={18}
                    borderRadius={100}
                    width={44}
                    height={20}
                />
            </div>
            {rightTooltip ?
                <Tooltip title={rightTooltip} placement="topLeft" className={Styles["switchSelect__tooltip"]}>
                    <p>{rightLabel}</p>
                    <InfoCircleOutlined />
                </Tooltip>
                :
                <p className={Styles["switchSelect__text"]}>{rightLabel}</p>
            }
        </div>
    );
};

export default SwitchSelect;
