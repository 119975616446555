import React from 'react';

import API from '../../helpers/API';
import Cookie from '../../helpers/Cookie';

import {
    _TLocaleState,
    ELocale,
    ELocaleActionType,
    TGetTranslationCallback,
    TLocaleContext,
    TLocaleProps,
    TLocaleTranslation,
    TTranslation,
    TTranslationValue
} from './interfaces';
import reducer from './reducer';

export const localeCookieName = '__locale';

const defaultLanguage: ELocale = ((navigator.language || (navigator as any).userLanguage).includes("en") ? ELocale.enGB : ELocale.frFr);
const defaultLocale = (Cookie.get(localeCookieName) as ELocale || null) ?? defaultLanguage;

export const defaultState: _TLocaleState = {
    nextLocale: defaultLocale,
    locale: defaultLocale,
};

export const LocaleContext = React.createContext<TLocaleContext>({
    ...defaultState,

    setLocale: () => {},

    getTranslation: () => '',
});

LocaleContext.displayName = 'Locale';

export const LocaleConsumer = LocaleContext.Consumer;

/**
 *  The global translation's object must use locale as key and translation's object as value.
 *
 *  The translation's object keys are translation keys and his values must be an array storing
 *  singular and plural formatted string
 *  or translation's object since the provider allowing deep recursive key.
 *
 *  Recursive key value must be separated by '.'.
 *  Finally, there are 2 way to inject variable into formatted translation.
 *  by using an object and js synthax ${variable} or using an array with printf synthax (%s, %d).
 *  Exemple:
 *      {
 *          [ELocale.frFr]: {
 *              'hello': ["Bonjour ${name} !"],
 *              'hello2': ["Bonjour %s !"],
 *              form: {
 *                  name: ['Nom du formulaire'] // Deep recursive key
 *              },
 *              cat: ['chat', 'chats'] // With plural value
 *          }
 *      }
 *
 * getTranslation('hello', { params: { name: "Toto" } }); // Expected: "Bonjour Toto !"
 * getTranslation('hello2', { params: ['Toto'] }); // Expected: "Bonjour Toto !"
 * getTranslation('form.name'); // Expected: "Nom du formulaire"
 * getTranslation('cat'); // Expected: "chat"
 * getTranslation('cat', { plural: true }); // Expected: "chats"
 *
 */
const LocaleProvider: React.FC<TLocaleProps> = ({
    translations: pTranslations = {},
    getTranslationFromRemote,
    renderKeyIfNotFound = true,
    renderLoading = null,
    injectLocaleIntoAPIOptions,
    children
}) => {
    const translations = React.useRef<TLocaleTranslation>(pTranslations);
    const [{
        locale,
        nextLocale,
        loading
    }, dispatch] = React.useReducer(reducer, {
        ...defaultState,
        loading: typeof getTranslationFromRemote === 'function',
    });

    const getTranslation = React.useCallback<TGetTranslationCallback>((key, {
        params = {},
        plural = false
    } = {}) => {
        let text = '';

        if (key) {
            const keyPaths = key.split('.');
            let _translation: TTranslation | TTranslationValue | undefined = translations.current[locale];

            for (let i = 0; _translation && i < keyPaths.length; ++i) {
                _translation = (_translation as TTranslation)[keyPaths[i]];
            }

            if (_translation) {
                let templateText = (_translation as TTranslationValue)[plural ? 1 : 0] || '';
                let currentParam = 0;

                for (let i = 0; i < templateText.length; i++) {
                    const currentChar = templateText.charAt(i);

                    if (Array.isArray(params)) {
                        if (currentChar === '%' && ['d', 's'].indexOf(templateText.charAt(i + 1)) !== -1) {
                            text += currentParam < params.length ? params[currentParam++] : '';
                            ++i;
                            continue;
                        }
                    }
                    else if (params) {
                        if (currentChar === '$') {
                            const nextChar = templateText.charAt(i + 1);

                            if (nextChar === '{') {
                                let extractName = '';
                                let extractChar;

                                for (let extractNameIndex = i + 2;
                                     extractNameIndex < templateText.length &&
                                     (extractChar = templateText.charAt(extractNameIndex)) !== '}';
                                     ++extractNameIndex
                                ) {
                                    extractName += extractChar;
                                }

                                if (extractChar === '}' && Array.isArray(params) === false) {
                                    i += extractName.length + 2;
                                    text += params[extractName] || '';
                                    continue;
                                }
                            }
                        }
                    }

                    text += currentChar;
                }
            }
            else {
                text = renderKeyIfNotFound ? key : '';
            }
        }
        return text;
    }, [locale, renderKeyIfNotFound]);

    const localeContext = React.useMemo<TLocaleContext>(() => ({
        locale,

        nextLocale,

        setLocale: locale => {
            Cookie.set(localeCookieName, locale);
            if (getTranslationFromRemote) {
                dispatch({ type: ELocaleActionType.SetRemote, locale: locale as ELocale });
            }
            else {
                dispatch({ type: ELocaleActionType.SetLocal, locale: locale as ELocale });
            }
        },

        getTranslation

    }), [locale, nextLocale, getTranslation, getTranslationFromRemote]);

    React.useEffect(() => {
        if (injectLocaleIntoAPIOptions) {
            API.setLocale!(nextLocale as string, injectLocaleIntoAPIOptions);
        }
        if (getTranslationFromRemote) {
            if (translations.current[nextLocale]) {
                dispatch({ type: ELocaleActionType.UpdateRemote });
            }
            else {
                getTranslationFromRemote(nextLocale)
                    .then(translation => {
                        translations.current[nextLocale] = translation;
                        dispatch({ type: ELocaleActionType.UpdateRemote });
                    });
            }
        }
    }, [getTranslationFromRemote, nextLocale, injectLocaleIntoAPIOptions]);

    return (loading ? renderLoading :
            <LocaleContext.Provider value={localeContext}>
                {children}
            </LocaleContext.Provider>
    );
};

export default LocaleProvider;