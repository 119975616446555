import API from "../index";
import {
    TLoginResponse,
    TRequestLoginCallback,
    TUserFromCookieCallback,
    TUserFromTokenCallback,
} from "./interfaces/login"
import {getTokenData} from "../../token";
import {TUser} from "../../../providers/AuthProvider/interfaces";

export const getLogin: TRequestLoginCallback = (email, password) => {
    const body = {
        "username": email,
        "password": password,
    }

    return API.post<TLoginResponse>('/login', body)
        .then(({ data }) => data)
}


export const getUser: TUserFromTokenCallback = (token) => {
    const tokenData = getTokenData(token)
    const user: TUser = {
        username: tokenData.username,
        firstname: tokenData.firstname,
        lastname: tokenData.lastname,
        clientAccountNumber: tokenData.clientAccountNumber,
        isClient: tokenData.isClient ?? false,
        isRep: tokenData.isRep ?? false,
        isManager: tokenData.isManager ?? false,
        commercialRepCode: tokenData.commercialRepCode,
        managerRepCode: tokenData.managerRepCode,
        skipOnboarding: tokenData.skipOnboarding
    }
    return user
}

export const getUserFromCookie: TUserFromCookieCallback = () => {
    const token = API.getTokenFromCookie!();
    return getUser(token!)
}
