import {
    IAuthState,
    TAuthReducer,
    EAuthActionType
} from './interfaces';

export const defaultState: IAuthState = {
    user: null,
};

const reducer: TAuthReducer = (prevState, {
        type,
        user
    }
) => {
    switch (type) {
        case EAuthActionType.Set:
            return {
                ...prevState,
                user: user!,
            };

        case EAuthActionType.Edit:
            return {
                ...prevState,
                user: {
                    ...prevState.user,
                    ...user!
                },
            };

        default:
            return prevState;
    }
};

export default reducer;