import React from 'react';

import { LocaleContext } from '../../providers/LocaleProvider';

import Logo from '../../globals/assets/logo_PF.png';

import Styles from './styles.module.scss';

const SplashScreen: React.FC = () => {
    const { getTranslation } = React.useContext(LocaleContext);

    return (
        <div className={Styles.splash_screen_container}>
            <p>
                <img src={Logo} alt='Logo PF' />
            </p>
            <div className={Styles.splash_screen_title}>
                <h2>{getTranslation('onboarding.splash_screen.subtitle')}</h2>
                <h1>{getTranslation('onboarding.splash_screen.title')}</h1>
            </div>
            <div className={Styles.splash_screen_description}>
                <p>{getTranslation('onboarding.splash_screen.description')}</p>
                <p>{getTranslation('onboarding.splash_screen.subDescription')}</p>
            </div>
        </div>
    );
};

export default SplashScreen;