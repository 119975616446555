import React from "react";
import {IManualInput} from "./interfaces";
import Styles from './styles.module.scss';
import {Form} from "antd";
import InputSearch from "../InputSearch";
import {LocaleConsumer} from '../../providers/LocaleProvider';

const ManualInput: React.FC<IManualInput> = ({onResult, manual, setManual}) => {
    const [form] = Form.useForm();
    const onFinish = (values: any) => {
        onResult(values.code)
    }
    const onSearch = () => {
        form.submit()
    }

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <div className={`${Styles["manualInput"]} ${!manual ? Styles["manualInput__small"] : ''}`}>
                    <Form form={form} onFinish={onFinish} className={Styles["manualInput__form"]} action="">
                        <InputSearch
                            label={getTranslation("scan_page.manual_input")}
                            fieldName="code"
                            rules={[
                                {
                                    required: true,
                                    message: getTranslation("scan_page.empty_submit_error"),
                                },
                            ]}
                            onClick={() => setManual(true)}
                            onSearch={onSearch}
                        />
                    </Form>
                </div>
            )}
        </LocaleConsumer>
    )
}

export default ManualInput
