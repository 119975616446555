import {TClient} from "../../../../providers/AuthProvider/interfaces";

export type TClientsResponse = {
    /**
     * list of clients
     */
    data: TClient[];
}

export enum ESearchType {
    client = 1,
    reps = 2,
    manager = 3
}

export interface IRequestClients {
    repCode?: string
    accountCode?: string
    accountName?: string
    email?: string
    authToken: string
    searchType?: ESearchType
    subsidiaryCode: string
}

export type TRequestClientsCallback = (search: IRequestClients) => Promise<TClientsResponse>;