import React, {useState} from "react";
import Scanner from "../../../components/Scanner";
import ManualInput from "../../../components/ManualInput";
import {useHistory} from "react-router-dom";
import ERoutes from "../../../routers/AuthRouter/routes";
import Styles from "./styles.module.scss";

const ScannerScreen: React.FC = () => {
    const [manual, setManual] = useState(false);
    const scanner = React.createRef<any>();
    const history = useHistory();

    const onResult = React.useCallback((code: string) => {
        scanner.current.stop();
        try {
            // Some qrcode urls contain spaces at the end of the url, which causes a bug and the product is not found.
            const url = new URL(code.trim());
            const productCode = url.pathname.substring(url.pathname.lastIndexOf('-') + 1);
            history.push(`${ERoutes.Product}?code=${productCode}`);
        }
        catch (_) {
            history.push(`${ERoutes.Product}?code=${code}`);
        }
    }, [history, scanner]);

    return (
        <div className={Styles["scannerContainer"]}>
            <Scanner ref={scanner} onResult={onResult} />
            <ManualInput onResult={onResult} manual={manual} setManual={setManual} />
        </div>
    )
}

export default ScannerScreen