import {ELocale, TTranslation} from "../../../providers/LocaleProvider/interfaces";

export const getTranslation = (locale: ELocale): Promise<TTranslation | undefined> => {
    const promise = new Promise<TTranslation | undefined>((resolve, reject) => {
        setTimeout(() => {
            fetch("translations/" + locale + ".json")
                .then((res) => resolve(res.json()))
                .catch((err) => {
                    console.log(err)
                    reject(err)
                })
        }, 0)
    })
    return promise.then((value) => value)
}