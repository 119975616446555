import React from 'react';

import {
    TUser,
    IAuthProps,
    EAuthActionType,
    TAuthTypeBTokenValues,
    TAuthRetrieveUserCallback,
    TAuthProps, IAuthState

} from './interfaces';
import reducer from './reducer';

import API from '../../helpers/API';
import Cookie, {tokenCookieName} from "../../helpers/Cookie";
import {getExpirationDate} from "../../helpers/token";

export const AuthContext = React.createContext<IAuthProps>({
    user: null,

    login: () => {},

    logout: () => {},

    editUser: () => {},
});

AuthContext.displayName = 'Auth';

export const AuthConsumer = AuthContext.Consumer;
const initialState: {state: IAuthState, ttl: number} = JSON.parse(localStorage.getItem("auth") as string)

const AuthProvider: React.FC<TAuthProps> = ({
    onRetrieveUserFromCookie,
    tokenExpirationField,
    tokenTimestampShift = 1000,
    renderLoading = null,
    children
}) => {
    const [loading, setLoading] = React.useState(true);
    const [state, dispatch] = React.useReducer(reducer,  initialState?.state || {user: null});

    /**
     * Config Authentification context's callbacks
     */
    const authContext = React.useMemo<IAuthProps>(() => ({
        ...state,

        login: (values, user) => {
            API.setAuthToken!(values as TAuthTypeBTokenValues, tokenExpirationField, tokenTimestampShift);
            const ttl = getExpirationDate(Cookie.get(tokenCookieName)!, 'exp', 1)?.getTime()
            localStorage.setItem("auth", JSON.stringify({state: {user: user}, ttl: ttl}))
            dispatch({ type: EAuthActionType.Set, user });
        },

        logout: () => {
            API.forgetAuthToken!();
            localStorage.removeItem("auth")
            dispatch({ type: EAuthActionType.Set, user: null });
        },

        editUser: (user: TUser) => {
            const ttl = getExpirationDate(Cookie.get(tokenCookieName)!, 'exp', 1)?.getTime()
            localStorage.setItem("auth", JSON.stringify({state: {user: user}, ttl: ttl}))
            dispatch({ type: EAuthActionType.Edit, user });
        }
    }),
    [state, tokenExpirationField, tokenTimestampShift]);

    /**
     * Check if user exist before loading page.
     */
    React.useEffect(() => {
        const token = API.getTokenFromCookie!();

        if (token) {
            const now = new Date()
            API.setAuthToken!(token, tokenExpirationField);
            if (initialState && initialState.state.user && initialState.ttl <= now.getTime()) {
                const user = initialState.state.user
                dispatch({ type: EAuthActionType.Set, user });
            } else {
                const user = onRetrieveUserFromCookie(token)
                dispatch({ type: EAuthActionType.Set, user });
            }
        }
        else {
            API.forgetAuth!();
            localStorage.removeItem("auth")
            setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * If token is valid, set loading to false once user is loaded.
     */
    React.useEffect(() => {
        if (loading && state.user !== null) {
            setLoading(false);
        }
    }, [loading, state.user]);

    return (loading ? renderLoading :
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    );
};

export type {
    TAuthRetrieveUserCallback
};

export default AuthProvider;