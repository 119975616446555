import React from "react";
import {IClientRow} from "./interfaces";
import Styles from "./styles.module.scss";
import {RightOutlined} from "@ant-design/icons";

const ClientRow: React.FC<IClientRow> = ({client, onClick}) => (
    <div onClick={() => onClick(client)}  className={Styles["clientRow"]}>
        <div className={Styles["clientRow__infos"]}>
            <h1 className={Styles["clientRow__title"]}>{client.accountName}</h1>
            <h2>{client.accountCode}</h2>
        </div>
        <RightOutlined />
    </div>
);

export default ClientRow