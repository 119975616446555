import React from "react";
import Styles from './styles.module.scss';
import {ISpinnerProps} from "./interfaces";

const Spinner: React.FC<ISpinnerProps> = ({onClick}) => {
    return (
        <div onClick={onClick} className={Styles["dot-flashing"]} />
    )
}

export default Spinner