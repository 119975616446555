import { AxiosRequestConfig } from "axios";

import API from "./API";

const g_cacheAvailable = 'caches' in window;
const g_cacheName = `react-cache-v${process.env.REACT_APP_VERSION}`;

const open = () => g_cacheAvailable ? window.caches.open(g_cacheName) : Promise.reject(new Error("Cache not available !"));

export const save = (urls: RequestInfo | RequestInfo[]): Promise<void> => (
    open()
    .then(cache => Promise.all([cache, cache.keys()]))
    .then(([cache, requests]) => {
        if (Array.isArray(urls)) {
            for (const request of requests) {
                const urlIndex = urls.indexOf(request.url);

                if (urlIndex !== -1) {
                    urls.splice(urlIndex, 1);
                }
            }

            if (urls.length > 0) {
                return cache.addAll(urls);
            }
        }
        else if (requests.findIndex(({ url }) => url === urls) === -1) {
            return cache.add(urls);
        }
    })
);

export const write = <T = any>(url: RequestInfo, datas: T) => (
    open()
    .then(cache => cache.put(url, new Response(JSON.stringify(datas))))
    .catch(err => {
        if (g_cacheAvailable) {
            throw err;
        }
    })
);

export const read = <T = any>(url: RequestInfo, options?: CacheQueryOptions) => (
    open()
    .then(cache => cache.match(url, options))
    .then<T>(response => {
        if (response === undefined) {
            throw new Error("Not found !");
        }
        return response.json();
    })
);

export const clear = (url: RequestInfo) => open().then(cache => cache.delete(url));

type TApiOptions<TResponse, TCacheData> = {
    /**
     * Event trigger to validate cache content.
     * if return false, the cache is invalidate and a new request is sent.
     */
    onValidate?: (data: TResponse) => boolean;

    /**
     * Event trigger used to format from response's format to the cache's format.
     */
    onWriteToCache?: (data: TResponse) => TCacheData;

    /**
     * Event trigger used to format from cache's format to the response's format.
     */
    onReadFromCache?: (data: TCacheData) => TResponse;
}

export const api = <TResponse = any, TCacheData = TResponse, TBody = any>(
    url: string,
    config?: AxiosRequestConfig<TBody>,
    {
        onValidate,
        onWriteToCache,
        onReadFromCache

    } : TApiOptions<TResponse, TCacheData> = {}
): Promise<TResponse> => (
    read<TCacheData>(`${API.defaults.baseURL}${url}`)
    .then(data => {
        const response = onReadFromCache ? onReadFromCache(data) : data as unknown as TResponse;

        if (onValidate && !onValidate(response)) {
            throw new Error('Validation\'s failed !');
        }
        return response;
    })
    .catch(() => (
        API.get<TResponse>(url, config)
        .then(({ data }) => 
            write<TCacheData>(`${API.defaults.baseURL}${url}`, onWriteToCache ? onWriteToCache(data) : data as unknown as TCacheData)
            .then(() => data)
        )
    ))
);