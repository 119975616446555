import React from 'react';
import {
    Route,
    Switch
} from "react-router-dom";

import ERoutes from './routes';
import {AnimatedSwitch, spring} from 'react-router-transition';

/**
 * Screens
 */
import Home from '../../screens/Auth/Home';
import ScannerScreen from "../../screens/Auth/Scan";
import Product from "../../screens/Auth/Product";
import Cart from "../../screens/Auth/Cart";
import OnboardingPage from '../../screens/OnboardingPage';

import MenuLayout from "../../layouts/MenuLayout";
import DeliveryScreen from "../../screens/Auth/Delivery";
import OrderSuccess from "../../screens/Auth/OrderSuccess";
import ErrorPage from "../../screens/ErrorPage";
import DeleteCart from "../../screens/Auth/DeleteCart";

import "../../globals/styles/transition.scss"
import OrderError from "../../screens/Auth/OrderError";
import PartialOrderError from "../../screens/Auth/PartialOrderError";

const AuthRouter: React.FC = () => {

    const glide = (val: number) => {
        return spring(val, {
            stiffness: 174,
            damping: 24,
        });
    }

    const pageTransitions = {
        atEnter: {
            offset: 100,
        },
        atLeave: {
            offset: glide(-100),
        },
        atActive: {
            offset: glide(0),
        },
    };

    return (
        <Switch>
            <Route exact path={ERoutes.Onboarding}>
                <OnboardingPage />
            </Route>
            <Route path='*'>
                <MenuLayout>
                    <AnimatedSwitch
                        className="switch-wrapper"
                        {...pageTransitions}
                        mapStyles={styles => ({
                            transform: `translateX(${styles.offset}%)`,
                        })}
                    >
                        <Route exact path={ERoutes.Home}>
                            <Home/>
                        </Route>
                        <Route exact path={ERoutes.Scanner}>
                            <ScannerScreen/>
                        </Route>
                        <Route exact path={ERoutes.Product}>
                            <Product/>
                        </Route>
                        <Route exact path={ERoutes.Cart}>
                            <Cart/>
                        </Route>
                        <Route exact path={ERoutes.OrderSuccess}>
                            <OrderSuccess/>
                        </Route>
                        <Route exact path={ERoutes.OrderError}>
                            <OrderError />
                        </Route>
                        <Route exact path={ERoutes.PartialOrderError}>
                            <PartialOrderError />
                        </Route>
                        <Route exact path={ERoutes.Delivery}>
                            <DeliveryScreen/>
                        </Route>
                        <Route exact path={ERoutes.DeleteCart}>
                            <DeleteCart/>
                        </Route>
                        <Route path="*">
                            <ErrorPage/>
                        </Route>
                    </AnimatedSwitch>
                </MenuLayout>
            </Route>
        </Switch>
    )
};

export default AuthRouter;