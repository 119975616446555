import React from "react";

import DeliveryHomeRow from "../DeliveryHomeRow";

import { IDeliveryList } from "./interfaces";

import Styles from "./styles.module.scss"

const DeliveryHomeList: React.FC<IDeliveryList> = ({
    addresses,
    selectedAddress,
    setSelectedAddress,
}) => (
    <div className={Styles["DeliveryHome__itemList"]}>
        <div className={Styles["DeliveryHome__items"]}>
            {addresses.map((address, index) => (
                <DeliveryHomeRow
                    key={index}
                    address={address}
                    onClick={() => setSelectedAddress(address === selectedAddress ? undefined : address)}
                    checked={address === selectedAddress}
                />
            ))}
        </div>
    </div>
);

export default DeliveryHomeList
