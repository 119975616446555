import React from "react";
import {Form, Input} from "antd";
import FloatLabel from "../FloatLabel";
import {IInputText} from "./interfaces";

const InputText: React.FC<IInputText> = ({
    label,
    value,
    setValue,
    fieldName,
    error = null,
    dependencies,
    rules,
    className,
    help,
    inputType = "string",
    maxLength
}) => {
    const validation = React.useMemo(() => {
        if (error !== null) {
            return {
                validateStatus: 'error' as 'error',
                help: error
            };
        }

        return undefined;
    }, [error]);

    const _label = React.useMemo(() => {
        const rule = rules?.find((_rule: any) => _rule.required === true);

        return rule ? `${label}*` : label;
    }, [label, rules]);

    return (
        <FloatLabel label={_label} value={value} className={className} help={help}>
            <Form.Item
                name={fieldName}
                initialValue={value}
                dependencies={dependencies}
                rules={rules}
                validateStatus={validation?.validateStatus}
                help={validation?.help}
            >
                <Input onChange={e => setValue!(e.target.value)} type={inputType} value={value} maxLength={maxLength} />
            </Form.Item>
        </FloatLabel>
    )
}

export default InputText