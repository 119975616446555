import React from "react";

export const OnboardingContext = React.createContext({
    canSkip: { current: false },
    canSkipNextTime: () => {},
    resetSkip: () => {}
});

OnboardingContext.displayName = 'Onboarding';

export const OnboardingConsumer = OnboardingContext.Consumer;

const OnboardingProvider: React.FC = ({
    children
}) => {
    const skip = React.useRef(false);

    const canSkipNextTime = React.useCallback(() => {
        skip.current = true;
    }, []);

    const resetSkip = React.useCallback(() => {
        skip.current = false;
    }, []);

    return (
        <OnboardingContext.Provider value={{ canSkip: skip, canSkipNextTime, resetSkip }}>
            {children}
        </OnboardingContext.Provider>
    )
}

export default OnboardingProvider