export enum EProductStatus {
    /**
     * Product is continued.
     */
    CONTINUED = "20",

    /**
     * Product is discontinued.
     */
    DISCONTINUED = "50",
};

export type TProduct = {
    code: string;
    designation: string;
    pattern: string;
    color: string;
    family: string;
    brandCode: string;
    brandName: string;
    collectionYear: string;
    pictureUrl: string;
    size: "small" | "large";
    status: EProductStatus;
    
}

export type TProductsResponse = {
    /**
     * list of products
     */
    data: TProduct[];
}

export type TRequestProductsCallback = (invalidate: boolean) => Promise<TProductsResponse>;