import React from "react";
import {IDeliveryShowroomRow} from "./interfaces";
import Styles from "./styles.module.scss";

const DeliveryShowroomRow: React.FC<IDeliveryShowroomRow> = ({
    showroom,
    onClick,
    checked = false
}) => {
    return (
      <div className={`${Styles["DeliveryShowroom__itemRow"]}${checked ? ` ${Styles["DeliveryShowroom__itemRow--checked"]}` : ''}`} onClick={onClick}>
        <div className={Styles["DeliveryShowroom__itemRowText"]}>
            <h2>{showroom.showroomDescription} Showroom</h2>
            <h2>{showroom.street1}</h2>
            <h2>{showroom.postalCode}</h2>
        </div>
      </div>
    )
}

export default DeliveryShowroomRow
