import React, {useContext, useEffect} from 'react';
import Styles from './styles.module.scss';
import BackgroundImageLayout from "../../../layouts/BackgroundImageLayout";
import backgroundImg from '../../../globals/assets/background_home.png'
import scanImg from '../../../globals/assets/scan.svg'
import Button from "../../../components/Button";
import ERoutes from "../../../routers/AuthRouter/routes";
import {LocaleConsumer} from '../../../providers/LocaleProvider';
import HTMLRender from "../../../components/HTMLRender";
import useQuery from "../../../hooks/useQuery";
import {AuthContext} from "../../../providers/AuthProvider";
import {ShowroomsContext} from "../../../providers/ShowroomProvider";
import * as NotAuthRoutes from "../../../routers/NotAuthRouter/routes";
import {useHistory} from "react-router-dom";
import {CartContext} from "../../../providers/CartProvider";

const Home : React.FC = () => {
    const history = useHistory();
    let query = useQuery()
    const showroom = query.get("showroom")
    const authContext = useContext(AuthContext)
    const showroomsContext = useContext(ShowroomsContext)
    const cartContext = useContext(CartContext)

    useEffect(() => {
        if (authContext.user?.showroom === undefined && !showroomsContext.showrooms.find(elem => elem.showroomCode === showroom)) {
            cartContext.empty()
            authContext.logout()
            history.push(NotAuthRoutes.default.Home)
        } else if (authContext.user?.showroom && showroom) {
            cartContext.empty()
            authContext.logout()
            history.push({pathname: NotAuthRoutes.default.Login,  search: `?showroom=${showroom}`})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <BackgroundImageLayout image={backgroundImg}>
                    <div className={Styles["home__container"]}>
                        <div className={Styles["home"]}>
                            <img alt="placehodler" className={Styles["home__image"]} src={scanImg}/>
                            <h2 className={Styles["home__text"]}>
                                <HTMLRender>{getTranslation("scan_home_page.description")}</HTMLRender>
                            </h2>
                            <Button styleType="plain" size="small" type="button" link={ERoutes.Scanner} label={getTranslation("scan_home_page.start_scanning")}/>
                        </div>
                    </div>
                </BackgroundImageLayout>
            )}
        </LocaleConsumer>
    )
}

export default Home;
