import {TProduct} from "../../helpers/API/requests/interfaces/products";

export type TCartItem = {
    /**
     * product object
     */
    product: TProduct

    /**
     * number of product
     */
    number: number
}

export interface ICartState {
    /**
     * products object array
     */
    items: TCartItem[];
}

export type TAddCallback = (item: TProduct) => void
export type TRemoveCallback = (item: TProduct) => void
export type TDeleteCallback = (item: TProduct) => void
export type TEmptyCallback = () => void

export interface ICartProps extends ICartState {
    /**
     * Add callback use to add an item to the cart
     */
    add: TAddCallback,

    /**
     * Remove callback use to remove one item of the cart
     */
    remove: TRemoveCallback,

    /**
     * Delete callback use to delete an item of the cart
     */
    eradicate: TDeleteCallback,

    /**
     * Empty callback use to empty the items array
     */
    empty: TEmptyCallback,
}

export enum ECartActionType {
    /**
     * Set Item on provider
     */
    Set = 'CART_SET_ITEM',

    /**
     * Update Item on provider
     */
    Edit = 'CART_EDIT_ITEM',

    /**
     * Delete Item on provider
     */
    Delete = 'CART_DELETE_ITEM',

    /**
     * Empty the item array
     */
    Empty = 'CART_EMPTY_ITEMS',
}

export type TCartAction = {
    /**
     * type of reducer's action
     */
    type: ECartActionType;

    /**
     * reducer's action parameter need to set or update item
     */
    item?: TCartItem;
}

export type TCartReducer = (prevState: ICartState, action: TCartAction) => ICartState;
