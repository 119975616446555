import React, {useContext, useEffect, useState} from "react";
import FilterClientsForm from "../../../forms/FilterClientsForm";
import {useHistory, useLocation} from "react-router-dom";
import {getUser} from "../../../helpers/API/requests/login";
import ERoutes from "../../../routers/NotAuthRouter/routes";
import * as AuthRoutes from "../../../routers/AuthRouter/routes";
import {getClients} from "../../../helpers/API/requests/clients";
import {AuthContext} from "../../../providers/AuthProvider";
import {notification} from "antd";
import {TClient} from "../../../providers/AuthProvider/interfaces";
import {TFilterCallback} from "../../../forms/FilterClientsForm/interfaces";
import ClientRow from "../../../components/ClientRow";
import {TClientSelectCallback} from "../../../components/ClientRow/interfaces";
import Styles from './styles.module.scss';
import ClientList from "../../../components/ClientList";
import API from "../../../helpers/API";
import Spinner from "../../../components/Spinner";
import {LocaleContext} from "../../../providers/LocaleProvider";
import {ShowroomsContext} from "../../../providers/ShowroomProvider";
import PromiseWorker from "../../../helpers/PromiseWorker";
import { OnboardingContext } from "../../../providers/OnboardingProvider";

const SelectClient : React.FC = () => {
    const history = useHistory();
    const location = useLocation<any>();

    const authContext = useContext(AuthContext);
    const showroomsContext = useContext(ShowroomsContext);
    const localeContext = useContext(LocaleContext);
    const { canSkip } = React.useContext(OnboardingContext);

    const [token, user] = React.useMemo(() => ([
        (location.state as any).token as string,
        getUser((location.state as any).token)
    ]), [location.state]);
    const showroom = React.useMemo(() => showroomsContext.showrooms.find(elem => elem.showroomCode === location.state.showroom), [location.state.showroom, showroomsContext.showrooms]);

    const [selfClient, setSelfClient] = useState<TClient | null>(null);
    const [fetchingSelfClient, setFetchingSelfClient] = useState<boolean>(true);
    const [clients, setClients] = useState<TClient[]>([]);
    const [loadingClients, setLoadingClients] = useState<boolean>(true);
    const [filteredClients, setFilteredClients] = useState<TClient[] | null>(null);
    
    const getSelfClient = React.useCallback(() => (
        getClients({accountCode: user.clientAccountNumber!, authToken: token, subsidiaryCode: showroom!.subsidiaryCode})
        .then(({ data }) => {
            setSelfClient(data[0]);
        })
        .finally(() => {
            setFetchingSelfClient(false);
        })
    ), [showroom, token, user.clientAccountNumber]);

    const getAllClients = React.useCallback(() => {
        // @ts-ignore
        const showroom = showroomsContext.showrooms.find(elem => elem.showroomCode === location.state.showroom)
        let hasError = false;

        // Create and execute all api call promises and store data
        return PromiseWorker({}, ...(user.isManager ? user.managerRepCode : user.commercialRepCode)!.map(repCode => () => getClients({
            authToken: token,
            repCode,
            subsidiaryCode: showroom!.subsidiaryCode
        })))
        .then((results) => {
            setClients(results.reduce((_clients, newClients) => {
                _clients.push(...newClients.data);
                return _clients;
            }, [] as TClient[]));
        })
        .catch(() => {
            notification['error']({
                placement: "bottomLeft",
                message: localeContext.getTranslation("select_client_page.error_fetching_client_list"),
            });
            hasError = true;
        })
        .finally(() => {
            setLoadingClients(false);
            if (hasError) {
                throw new Error("Failed to retrieve clients !");
            }
        });
    }, [localeContext, location.state.showroom, showroomsContext.showrooms, token, user.commercialRepCode, user.isManager, user.managerRepCode]);

    const onFilter = React.useCallback<TFilterCallback>(({clientName, clientCode, clientEmail}) => {
        if (!user || !user.commercialRepCode) {
            console.error("Can't search client. User not found")
            return
        }
        let tmpFiltered: TClient[] = [];
        
        if (clientName && clientName !== "") {
            tmpFiltered = clients.filter(({ accountName }) => accountName?.toLowerCase().includes(clientName.toLowerCase()));
        }
        else if (clientCode && clientCode !== "") {
            tmpFiltered = clients.filter(({ accountCode }) => accountCode?.toLowerCase() === clientCode.toLowerCase());
        }
        else if (clientEmail && clientEmail !== "") {
            tmpFiltered = clients.filter(({ email }) => email?.some(_email => _email.toLowerCase().includes(clientEmail.toLowerCase())));
        }
        setFilteredClients(tmpFiltered);
    }, [clients, user]);

    const onClientSelected = React.useCallback<TClientSelectCallback>(client => {
        const showroom = location.state.showroom;

        API.setAuthToken!(token);
        authContext.login(token, {...user!, client, showroom });
        history.push(canSkip.current ? ({
            pathname: AuthRoutes.default.Home,
        }) : ({
            pathname: AuthRoutes.default.Onboarding,
            state: {
                pathname: AuthRoutes.default.Home
            }
        }));
    }, [authContext, canSkip, history, location.state.showroom, token, user]);

    useEffect(() => {
        if (!user || !(user.isManager || user.isRep)) {
            history.push(ERoutes.Login);
        }
        if (user.isClient) {
            getSelfClient();
        }
        getAllClients()
        .catch(() => {
            history.push(ERoutes.Login);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <>
            <div className={Styles["selectClient__filters"]}>
                <FilterClientsForm onFilter={onFilter} />
            </div>
            <div className={Styles["selectClient__clients"]}>
                {user && user.isClient &&
                    <>
                    {!fetchingSelfClient ?
                        (selfClient &&
                            <ClientRow onClick={onClientSelected} client={selfClient}/>
                        )
                        :
                        <Spinner onClick={getSelfClient} />
                    }
                    </>
                }
                {loadingClients ?
                    <Spinner />
                :
                    <ClientList clients={filteredClients} onClick={onClientSelected} />
                }
            </div>
        </>
    )
}

export default SelectClient