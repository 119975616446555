import {ECartActionType, ICartState, TCartReducer} from "./interfaces";

const reducer: TCartReducer = (prevState, {
    type,
    item
}) => {
    const index = prevState.items.findIndex(elem => elem.product.code === item?.product.code && elem.product.size === item?.product.size)
    let newState: ICartState | undefined = prevState

    switch (type) {
        case ECartActionType.Set:
            newState = {
                ...prevState,
                items: [...prevState.items, item!]
            };
            localStorage.setItem("cart", JSON.stringify(newState))
            return newState

        case ECartActionType.Edit:
            prevState.items[index] = item!
            newState = {
                ...prevState,
                items: [
                    ...prevState.items,
                ]
            };
            localStorage.setItem("cart", JSON.stringify(newState))
            return newState

        case ECartActionType.Delete:
            let tmp = [...prevState.items]
            tmp.splice(index, 1)
            newState = {
                ...prevState,
                items: tmp!
            }
            localStorage.setItem("cart", JSON.stringify(newState))
            return newState

        case ECartActionType.Empty:
            newState = {
                items: []
            }
            localStorage.setItem("cart", JSON.stringify(newState))
            return newState

        default:
            return prevState
    }
}

export default reducer