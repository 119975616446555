import ERoutes from "../routers/AuthRouter/routes";
import * as H from "history";

const parseOrderErrorRedirect = (error: any, history: H.History<any>): boolean => {
    try {
        let successNb = 0
        let errorNb = 0
        for (let key in error.response.data.error.orderLine) {
            if (error.response.data.error.orderLine[key] === "Order made") {
                successNb++
            } else {
                errorNb++
            }
        }
        history.push({pathname: ERoutes.PartialOrderError, state: {code: error.response.data.error.header.ERPOrderNumber, successNb: successNb, errorNb: errorNb}})
        return true
    } catch (e) {history.push(ERoutes.OrderError)}
    return false
}

export default parseOrderErrorRedirect