import React, {useState} from "react";
import Styles from './styles.module.scss';
import {IFloatLabel} from "./interfaces";

const FloatLabel: React.FC<IFloatLabel> = ({
    label,
    value,
    className,
    help,
    children
}) => {
    const [focus, setFocus] = useState(false);
    const labelClass = focus || value ? `${Styles["floatLabel__label"]} ${Styles["floatLabel__labelFloating"]}`: Styles["floatLabel__label"]

    return (
        <div
            className={`${Styles["floatLabel"]} ${className}`}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
        >
            {children}
            <label className={labelClass}>{label}</label>
            {help && <p className={Styles.floatLabel__help}>{help}</p>}
        </div>
    );
};

export default FloatLabel;