import React from "react";
import Styles from "./styles.module.scss";
import {LocaleConsumer} from "../../providers/LocaleProvider";
import {ShowroomsConsumer} from "../../providers/ShowroomProvider"
import {AuthConsumer} from "../../providers/AuthProvider"
import {IDeliveryShowroomSelection} from "./interfaces";
// import {SelectValue} from "antd/es/select";
import DeliveryShowroomList from "../DeliveryShowroom/DeliveryShowroomList";

const DeliveryShowroomSelection: React.FC<IDeliveryShowroomSelection> = ({ setSelectedShowroom, selectedShowroom }) => {

    // const onSelectChange = React.useCallback((value: SelectValue) => {
    //     setShowroom(value?.toString());
    // }, [setShowroom]);

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <ShowroomsConsumer>
                    {({ showrooms }) => (
                        <AuthConsumer>
                            {({ user }) => (
                                <>
                                    {(user?.isRep || user?.isManager) ?
                                        /*<div className={Styles["deliveryShowroom__container"]}>
                                            <Select
                                                className={Styles["deliveryShowroom__select"]}
                                                suffixIcon={<img alt="drop icon" src={dropdownIcon}/>}
                                                onChange={onSelectChange}
                                                defaultValue={user?.showroom}
                                            >
                                                {showrooms.filter(e => e.subsidiaryCode === user.client?.pierreFreySubsidiary).map((showroom: TShowroom) => (
                                                    <Select.Option key={showroom.showroomCode} value={showroom.showroomCode}>
                                                        {showroom.showroomDescription + ' - ' + showroom.street1}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>*/
                                        <>
                                            <DeliveryShowroomList
                                                showrooms={showrooms.filter(e => e.subsidiaryCode === user.client?.pierreFreySubsidiary)}
                                                setSelectedShowroom={setSelectedShowroom}
                                                selectedShowroom={selectedShowroom}
                                            />
                                        </>
                                        :
                                        <div className={Styles["deliveryShowroom__container"]}>
                                            <h1 className={Styles["deliveryShowroom__showroomName"]}>{showrooms.find(elem => elem.showroomCode === user?.showroom)?.showroomDescription + ' - ' + showrooms.find(elem => elem.showroomCode === user?.showroom)?.street1}</h1>
                                        </div>
                                    }
                                </>
                            )}
                        </AuthConsumer>
                    )}
                </ShowroomsConsumer>
            )}
        </LocaleConsumer>
    )
}

export default DeliveryShowroomSelection