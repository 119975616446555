import React from "react";

import { getProducts } from "../../helpers/API/requests/products";

import { TProduct } from "../../helpers/API/requests/interfaces/products";

import { InvalidateContext } from "../InvalidateProvider";

export const ProductsContext = React.createContext({
    products: [] as TProduct[],
    loading: true
});

ProductsContext.displayName = 'Products';

export const ProductsConsumer = ProductsContext.Consumer;

// const initialState: {state: IProductsState, ttl: number} = JSON.parse(localStorage.getItem("products") as string)

const ProductsProvider: React.FC = ({
    children
}) => {
    const { shouldInvalidate, hasInvalidate } = React.useContext(InvalidateContext);

    const [products, setProducts] = React.useState<TProduct[]>([]);
    const [loading, setloading] = React.useState(true);

    React.useEffect(() => {
        // const now = new Date()
        // if (!initialState || initialState.ttl <= now.getTime()) {
        //     getProducts().then((data) => productsContext.set(data.data))
            
        // }

        getProducts(shouldInvalidate())
            .then(({ data }) => {
                hasInvalidate();
                setProducts(data);
            })
            .catch(console.error)
            .finally(() => {
                setloading(false);
            })
        ;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // React.useEffect(() => {
    //     const now = new Date()
    //     localStorage.setItem("products", JSON.stringify({state: products, ttl: now.getTime() + (1000 * 60 * 60 * 24)}))
    // }, [products]);

    return (
        <ProductsContext.Provider value={{ products, loading }}>
            {children}
        </ProductsContext.Provider>
    )
}

export default ProductsProvider