import React from "react";
import Styles from "./styles.module.scss";
import ItemList from "../../../components/ItemList";
import {AuthConsumer} from "../../../providers/AuthProvider";
import {LocaleConsumer} from "../../../providers/LocaleProvider";
import {CartConsumer} from "../../../providers/CartProvider";
import Button from "../../../components/Button";
import BottomMenuLayout from "../../../layouts/BottomMenuLayout";
import ERoutes from "../../../routers/AuthRouter/routes";

const Cart: React.FC = () => {
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
            <CartConsumer>
                {({ items }) => (
                <div className={Styles["cart"]}>
                    <div className={Styles["cart__titleLine"]} />
                    <h1 className={`title ${Styles["cart__title"]}`}>{getTranslation("cart_page.title")}</h1>
                    <AuthConsumer>
                        {({ user }) =>
                            <h2>{user!.firstname} {user!.lastname}</h2>
                        }
                    </AuthConsumer>
                    <ItemList />
                    <BottomMenuLayout>
                        {items.length > 0 &&
                        <Button
                            styleType="plain"
                            size="large"
                            type="button"
                            link={ERoutes.Delivery}
                            label={getTranslation("cart_page.order")}
                            className={Styles["cart__orderButton"]}
                        />
                        }
                        <Button
                            styleType="text"
                            size="large"
                            type="button"
                            link={ERoutes.Scanner}
                            label={getTranslation("cart_page.scan_new_product")}
                            className={Styles["cart__textButton"]}
                        />
                    </BottomMenuLayout>
                </div>
                )}
            </CartConsumer>
            )}
        </LocaleConsumer>
    )
}

export default Cart