import React from "react";
import {IDeliveryHomeRow} from "./interfaces";
import Styles from "./styles.module.scss";

const DeliveryHomeRow: React.FC<IDeliveryHomeRow> = ({
    address,
    onClick,
    checked = false
}) => {
    return (
      <div className={`${Styles["DeliveryHome__itemRow"]}${checked ? ` ${Styles["DeliveryHome__itemRow--checked"]}` : ''}`} onClick={onClick}>
        <div className={Styles["DeliveryHome__itemRowText"]}>
            <h2>{address.addressName}</h2>
            <h2>{address.address1}</h2>
            <h2>{address.postalCode}</h2>
            <h2>{address.address3}</h2>
            <h2>{address.address4}</h2>
        </div>
      </div>
    )
}

export default DeliveryHomeRow
