import React from 'react';

import { TStepBarCounter } from './interfaces';

import Styles from './styles.module.scss';

const StepBarCounter: React.FC<TStepBarCounter> = ({ nbSteps, currentStep, className }) => (
    <ul className={`${Styles.container}${className ? ` ${className}` : ''}`}>
        {(new Array(nbSteps)).fill(0).map((_, i) => (
            <li key={`StepBarCounter-${i}`} className={`${Styles.item}${currentStep === i + 1 ? ` ${Styles['item--active']}` : ''}`}></li>
        ))}
    </ul>
);

export default StepBarCounter;