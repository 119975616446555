import React from "react";

import DeliveryShowroomRow from "../DeliveryShowroomRow";

import { IDeliveryList } from "./interfaces";

import Styles from "./styles.module.scss"

const DeliveryShowroomList: React.FC<IDeliveryList> = ({
    showrooms,
    selectedShowroom,
    setSelectedShowroom,
}) => (
    <div className={Styles["DeliveryShowroom__itemList"]}>
        <div className={Styles["DeliveryShowroom__items"]}>
            {showrooms.map((showroom, index) => (
                <DeliveryShowroomRow
                    key={index}
                    showroom={showroom}
                    onClick={() => setSelectedShowroom(showroom === selectedShowroom ? undefined : showroom)}
                    checked={showroom === selectedShowroom}
                />
            ))}
        </div>
    </div>
);

export default DeliveryShowroomList
