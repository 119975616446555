import React from "react";
import Styles from './styles.module.scss';
import {LocaleConsumer} from '../../providers/LocaleProvider';
import HTMLRender from "../HTMLRender";

const PrivacyPolicy: React.FC = () => {
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <div className={Styles["privacyPolicy"]}>

                    <h1 className="title">{getTranslation("privacy_policy_page.title")}</h1>
                    <div className={Styles["privacyPolicy__text"]}>
                        <HTMLRender>{getTranslation("privacy_policy_page.content")}</HTMLRender>
                    </div>
                </div>
            )}
        </LocaleConsumer>
    );
};

export default PrivacyPolicy;