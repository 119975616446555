enum ERoutes {

    /**
     * Default home page route.
     */
    Home = '/',
    
    /**
     * Login page, use by API to redirect in case of token expiration.
     */
    Login = '/login',

    /**
     * Select rep's client
     */
    SelectClient = "/clients"
}

export default ERoutes;