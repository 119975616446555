import React from "react";
import {IClientList} from "./interfaces";
import ClientRow from "../ClientRow";
import Styles from "./styles.module.scss";
import {LocaleConsumer} from '../../providers/LocaleProvider';

const ClientList: React.FC<IClientList> = ({clients, onClick}) => (
    <LocaleConsumer>
        {({ getTranslation }) => (
            <>
                {!clients?.length ?
                    <h1 className={Styles["clientList__h1"]}>{getTranslation(clients === null ? "select_client_page.not_submitted" : "select_client_page.no_result")}</h1>
                :
                    <>
                    <h2 className={Styles["clientList__h2"]}>{getTranslation("select_client_page.client_list")}</h2>
                    {clients.map((client) => (
                        <ClientRow key={client.accountCode} onClick={onClick} client={client}/>
                    ))}
                    </>
                }
                
            </>
        )}
    </LocaleConsumer>
);

export default ClientList