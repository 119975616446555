import TextPageLayout from "../../layouts/TextPageLayout";
import Styles from "../../components/PrivacyPolicy/styles.module.scss";
import {LeftOutlined} from "@ant-design/icons";
import React from "react";
import {useHistory} from "react-router-dom";
import Infos from "../../components/Infos";

const InfoPage = () => {
    const history = useHistory()

    const cancel = () => {
        history.goBack()
    }

    return (
        <TextPageLayout>
            <div className={Styles["privacyPolicy__backButton"]} onClick={cancel}>
                <LeftOutlined />
            </div>
            <Infos />
        </TextPageLayout>
    )
}

export default InfoPage