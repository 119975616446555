import {IShowroomsState, TShowroomsReducer} from "./interfaces";

export const defaultState: IShowroomsState = {
    showrooms: []
}

const reducer: TShowroomsReducer = (prevState, {
    showrooms
}) => {
    return {
        showrooms: showrooms
    }
}

export default reducer