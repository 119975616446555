import React from "react";
import Styles from "./styles.module.scss"
import BackgroundImageLayout from "../../layouts/BackgroundImageLayout";
import image from "../../globals/assets/warning.svg"
import backgroundImage from "../../globals/assets/error_page.png"
import Button from "../../components/Button";
import {useHistory} from "react-router-dom";
import {LocaleConsumer} from "../../providers/LocaleProvider";

const ErrorPage: React.FC = () => {
    const history = useHistory();

    const goBack = () => {
        history.goBack()
    }

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <BackgroundImageLayout image={backgroundImage}>
                    <div className={Styles["errorPage"]}>
                        <div className={Styles["errorPage__infos"]}>
                            <img alt="logo" src={image} className={Styles["errorPage__img"]} />
                            <h2>{getTranslation("error_page.description")}</h2>
                        </div>
                        <div className={Styles["errorPage__buttons"]}>
                            <Button
                                styleType="plain"
                                size="small"
                                type="button"
                                label={getTranslation("error_page.back")}
                                onClick={goBack}
                                className={Styles["errorPage__buttonBack"]}
                            />
                            <div className={Styles["errorPage__emptyButton"]} />
                        </div>
                    </div>
                </BackgroundImageLayout>
            )}
        </LocaleConsumer>
    )
}

export default ErrorPage