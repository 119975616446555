import React from "react";
import Styles from './styles.module.scss'
import {IProductStock} from "./interfaces";
import moment from "moment";
import {LocaleConsumer} from '../../providers/LocaleProvider';

const ProductStock: React.FC<IProductStock> = ({
    stock,
}) => {
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <div className={Styles["productStock"]}>
                    <div className={Styles["productStock__header"]}>
                        <h2>{stock && stock.totalStockQuantity !== 0 ? getTranslation("product_page.stock.available") : getTranslation("product_page.stock.unavailable")}</h2>
                    </div>
                    {stock?.resultCurrentStockBatch.map((value, index) => (
                        <div key={index} className={Styles["productStock__listItem"]}>
                            <p>{getTranslation("product_page.piece")} {value.batchNumber}</p>
                            <p>{Math.floor(value.batchAvailableStockQuantity)} {getTranslation("product_page.unit." + stock.unit, {plural: Math.floor(value.batchAvailableStockQuantity) > 1})}</p>
                        </div>
                    ))}
                    {stock?.resultCurrentStockBatch.length! > 0 &&
                        <div className={`${Styles["productStock__listItem"]} ${Styles["productStock__newStock"]}`}>
                            <p>{getTranslation("product_page.stock.total")}</p>
                            <p>{Math.floor(stock!.totalAvailableStockQuantity)} {getTranslation("product_page.unit." + stock!.unit, {plural: Math.floor(stock!.totalAvailableStockQuantity) > 1})}</p>
                        </div>
                    }
                    {stock && stock.resultNextProduction.length > 0 &&
                        <>
                            <div className={`${Styles["productStock__listItem"]} ${Styles["productStock__newStock"]}`}>
                                <p>{getTranslation("product_page.stock.new_stock")}</p>
                                <p>{moment(stock?.resultNextProduction[0].nextProductionDeliveryDate).format("DD/MM/YYYY")}</p>
                            </div>
                            <div className={`${Styles["productStock__listItem"]} ${Styles["productStock__newStock"]}`}>
                                <p/>
                                <p>{Math.floor(stock?.resultNextProduction[0].nextProductionQuantity)} {getTranslation("product_page.unit." + stock.unit, {plural: Math.floor(stock?.resultNextProduction[0].nextProductionQuantity) > 1})}</p>
                            </div>
                        </>
                    }
                </div>
            )}
        </LocaleConsumer>
    )
}

export default ProductStock