import React from "react";
import Styles from './styles.module.scss';
import HTMLRender from "../HTMLRender";
import {LocaleConsumer} from "../../providers/LocaleProvider";

const Infos: React.FC = () => {
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <div className={Styles["Infos"]}>
                    <h1 className="title">{getTranslation("info_page.title")}</h1>
                    <h2 className={Styles["Infos__h2"]}>{getTranslation("info_page.sub_title")}</h2>
                    <p className={Styles["Infos__text"]}>
                        <HTMLRender>{getTranslation("info_page.content")}</HTMLRender>
                    </p>
                </div>
            )}
        </LocaleConsumer>
    );
};

export default Infos;