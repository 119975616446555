import React from 'react';

const useDidUpdate = (callback: React.EffectCallback, dependencies: React.DependencyList): void => {
    const didMount = React.useRef(false);

    React.useEffect(() => {
        if (didMount.current) {
            return callback();
        }
        else {
            didMount.current = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}

export default useDidUpdate;