import React from "react";
import Styles from './styles.module.scss'

const TextPageLayout: React.FC = ({
  children
}) => {
    return (
        <div className={Styles["text_page_layout"]}>
            {children}
        </div>
    )
}

export default TextPageLayout