import React from "react";

export const InvalidateContext = React.createContext({
    shouldInvalidate: (): boolean => true,
    invalidate: () => {},
    hasInvalidate: () => {},
});

InvalidateContext.displayName = 'Invalidate';

export const InvalidateConsumer = InvalidateContext.Consumer;

const InvalidateProvider: React.FC = ({
    children
}) => {
    const _invalidate = React.useRef(false);

    const invalidate = React.useCallback(() => {
        _invalidate.current = true;
    }, []);

    const hasInvalidate = React.useCallback(() => {
        _invalidate.current = false;
    }, []);

    const shouldInvalidate = React.useCallback(() => _invalidate.current, []);

    return (
        <InvalidateContext.Provider value={{ invalidate, hasInvalidate, shouldInvalidate }}>
            {children}
        </InvalidateContext.Provider>
    )
}

export default InvalidateProvider