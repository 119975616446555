
const PromiseWorker = <T = any, Ret = T[]>({ 
    concurrency = 4,
}, ...promisesFactory: (() => Promise<T>)[]) => new Promise<Ret>((resolve, reject) => {
    const _promisesFactory = promisesFactory;
    const results: any[] = [];

    (function startWork() {
        if (promisesFactory.length === 0) {
            resolve(results as unknown as Ret);
        }
        else {
            Promise.all(_promisesFactory.splice(0, concurrency).map(_promiseFactory => _promiseFactory()))
            .then(_results => {
                results.push(..._results);
                startWork();
            })
            .catch(reject);
        }
    })();
});

export default PromiseWorker;