import React from 'react';
import {
    Switch,
    Route,
} from "react-router-dom";

import ERoutes from './routes';

/**
 * Screens
 */
import Home from '../../screens/NotAuth/Home';
import Login from "../../screens/NotAuth/Login";
import MenuLayout from "../../layouts/MenuLayout";
import SelectClient from "../../screens/NotAuth/SelectClient";

const NotAuthRouter: React.FC = () => (
    <MenuLayout>
        <Switch>
            <Route exact path={ERoutes.Home}>
                <Home />
            </Route>
            <Route exact path={ERoutes.Login}>
                <Login />
            </Route>
            <Route exact path={ERoutes.SelectClient}>
                <SelectClient />
            </Route>
        </Switch>
    </MenuLayout>
);

export default NotAuthRouter;